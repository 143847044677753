.SingleSelect :global {

  input[type="radio"] {
    appearance: none;

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      transition: 120ms transform ease-in-out;
      box-shadow: inset 2em 2em #65236c;
      transform: scale(0);
    }

    &:checked::before {
      transform: scale(0.7);
    }

    &:disabled, &:disabled+label {
      opacity: 0.3;
      cursor: default;
    }
  }

  .inputOption:not(:first-child) {
    border-top: 1px #efe5ef solid;
  }
}