@tailwind base;
@tailwind components;
@tailwind utilities;

/* phone number label style workaround */
.react-tel-input .special-label {
    font-size: 10px !important;
}

/* React Modal animation */
.ReactModal__Overlay {
    opacity: 0;
    transition: all 300ms ease-in-out;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
}

.ReactModal__Overlay--before-close {
    opacity: 0;
}

.ReactModal__Content {
    position: absolute;
    top: 100px; /* Starts 100px from the top of the viewport */
    left: 50%;
    transform: translate(-50%, -100px); /* Moves the modal out of view initially */
    opacity: 0;
    transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
}

.ReactModal__Content--after-open {
    transform: translate(-50%, 0); /* Moves the modal to its final position */
    opacity: 1;
}

.ReactModal__Content--before-close {
    transform: translate(-50%, -100px); /* Moves the modal back out of view */
    opacity: 0;
}

/* width */
::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #D9D9D9;
    border-radius: 10px;
}

/* Thumb */
::-webkit-scrollbar-thumb {
    background: #A1A1A1;
    border-radius: 10px;
}