.MarkdownContainer :global {
  p, ul, ol, blockquote {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  h1, h2, h3 {
    font-weight: bold;
    &:not(:first-child) {
      margin-top: 2em;
    }
    a { color: unset; }
  }

  a {
    color: #c7fe70;
  }

  h1 {
    font-size: 2em;
    color: #c7fe70;
  }

  h2 { font-size:1.25em; }

  ul, ol {
    padding-left: 1em;
  }

  ul { list-style: circle; }
  ol { list-style: decimal; }

  blockquote {
    font-style: italic;
    position: relative;
    padding-left: 2em;
    border-left: rgba(128,128,128,0.6) 2px solid;
    margin-top: 2.5rem;
    margin-bottom: 0.5rem;

    &::before {
      content:"\"";
      color: rgba(128,128,128,0.6);
      position: absolute;
      font-size: 5em;
      top:-3rem;
      left:0;
    }
  }

  code {
    background: rgba(128,128,128,0.6);
    border-radius: 2px;
    padding: 0 3px;
    margin: 0 3px;
  }
}