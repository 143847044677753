.wrapper {
    background-color: white;
    min-height: 100vh;
    display: flex;
    padding: 50px 15px;
    @media screen and (max-width: 700px), (max-height: 500px) {
      flex-wrap: wrap;
      flex-direction: column;
    }
  }
  
  .card-form {
    max-width: 570px;
    margin: auto;
    width: 100%;
  
    @media screen and (max-width: 576px) {
      margin: 0 auto;
    }
  
    &__inner {
      background: #fff;
      // box-shadow: 3px 13px 30px 0px rgba(21, 34, 67, 0.2);
      box-shadow: 0 30px 60px 0 rgba(90, 116, 148, 0.4);
      border-radius: 10px;
      padding: 35px;
      padding-top: 180px;
  
      @media screen and (max-width: 480px) {
        padding: 25px;
        padding-top: 165px;
      }
      @media screen and (max-width: 360px) {
        padding: 15px;
        padding-top: 165px;
      }
    }
  
    &__row {
      display: flex;
      align-items: flex-start;
      @media screen and (max-width: 480px) {
        flex-wrap: wrap;
      }
    }
  
    &__col {
      flex: auto;
      margin-right: 35px;
  
      &:last-child {
        margin-right: 0;
      }
  
      @media screen and (max-width: 480px) {
        margin-right: 0;
        flex: unset;
        width: 100%;
        margin-bottom: 20px;
  
        &:last-child {
          margin-bottom: 0;
        }
      }
  
      &.-cvv {
        max-width: 150px;
        @media screen and (max-width: 480px) {
          max-width: initial;
        }
      }
    }
  
    &__group {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
  
      .card-input__input {
        flex: 1;
        margin-right: 15px;
  
        &:last-child {
          margin-right: 0;
        }
      }
    }
  
    &__button {
      width: 100%;
      height: 55px;
      background: #2364d2;
      border: none;
      border-radius: 5px;
      font-size: 22px;
      font-weight: 500;
      font-family: "Montserrat", sans-serif;
      box-shadow: 3px 10px 20px 0px rgba(35, 100, 210, 0.3);
      color: #fff;
      margin-top: 20px;
      cursor: pointer;
  
      @media screen and (max-width: 480px) {
        margin-top: 10px;
      }
    }
  }
  
  .card-item {
    max-width: 430px;
    height: 270px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    z-index: 2;
    width: 100%;
  
    @media screen and (max-width: 480px) {
      max-width: 310px;
      height: 220px;
      width: 90%;
    }
  
    @media screen and (max-width: 360px) {
      height: 180px;
    }
  
    &.active {
      .card-item__side {
        &.front {
          transform: perspective(1000px) rotateY(180deg) rotateX(0deg)
            rotateZ(0deg);
        }
        &.back {
          transform: perspective(1000px) rotateY(0) rotateX(0deg) rotateZ(0deg);
          // box-shadow: 0 20px 50px 0 rgba(81, 88, 206, 0.65);
        }
      }
    }
  
    &__focus {
      position: absolute;
      z-index: 3;
      border-radius: 5px;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      transition: all 0.35s cubic-bezier(0.71, 0.03, 0.56, 0.85);
      opacity: 0;
      pointer-events: none;
      overflow: hidden;
      border: 2px solid rgba(255, 255, 255, 0.65);
  
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        background: rgb(8, 20, 47);
        height: 100%;
        border-radius: 5px;
        filter: blur(25px);
        opacity: 0.5;
      }
  
      &.active {
        opacity: 1;
      }
    }
  
    &__side {
      border-radius: 15px;
      overflow: hidden;
      // box-shadow: 3px 13px 30px 0px rgba(11, 19, 41, 0.5);
      box-shadow: 0 20px 60px 0 rgba(14, 42, 90, 0.55);
      transform: perspective(2000px) rotateY(0deg) rotateX(0deg) rotate(0deg);
      transform-style: preserve-3d;
      transition: all 0.8s cubic-bezier(0.71, 0.03, 0.56, 0.85);
      backface-visibility: hidden;
      height: 100%;
  
      &.back {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        transform: perspective(2000px) rotateY(-180deg) rotateX(0deg) rotate(0deg);
        z-index: 2;
        padding: 0;
        // background-color: #2364d2;
        // background-image: linear-gradient(
        //   43deg,
        //   #4158d0 0%,
        //   #8555c7 46%,
        //   #2364d2 100%
        // );
        height: 100%;
  
        .card-item__cover {
          transform: rotateY(-180deg)
        }
      }
    }
    &__bg {
      max-width: 100%;
      display: block;
      max-height: 100%;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    &__cover {
      height: 100%;
      position: absolute;
      height: 100%;
      background-color: #1c1d27;
      background-image: linear-gradient(147deg, #354fce 0%, #0c296b 74%);
      left: 0;
      top: 0;
      width: 100%;
      border-radius: 15px;
      overflow: hidden;
      &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(6, 2, 29, 0.45);
      }
    }
  
    &__top {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 40px;
      padding: 0 10px;
  
      @media screen and (max-width: 480px) {
        margin-bottom: 25px;
      }
      @media screen and (max-width: 360px) {
        margin-bottom: 15px;
      }
    }
  
    &__chip {
      width: 60px;
      @media screen and (max-width: 480px) {
        width: 50px;
      }
      @media screen and (max-width: 360px) {
        width: 40px;
      }
    }
  
    &__type {
      height: 45px;
      position: relative;
      display: flex;
      justify-content: flex-end;
      max-width: 100px;
      margin-left: auto;
      width: 100%;
  
      @media screen and (max-width: 480px) {
        height: 40px;
        max-width: 90px;
      }
      @media screen and (max-width: 360px) {
        height: 30px;
      }
    }
  
    &__typeImg {
      max-width: 100%;
      object-fit: contain;
      max-height: 100%;
      object-position: top right;
    }
  
    &__info {
      color: #fff;
      width: 100%;
      max-width: calc(100% - 85px);
      padding: 10px 15px;
      font-weight: 500;
      display: block;
  
      cursor: pointer;
  
      @media screen and (max-width: 480px) {
        padding: 10px;
      }
    }
  
    &__holder {
      opacity: 0.7;
      font-size: 14px;
      margin-bottom: 6px;
      @media screen and (max-width: 480px) {
        font-size: 12px;
        margin-bottom: 5px;
      }
    }
  
    &__wrapper {
      font-family: "Montserrat", monospace;
      padding: 25px 15px;
      position: relative;
      z-index: 4;
      height: 100%;
      text-shadow: 7px 6px 10px rgba(14, 42, 90, 0.8);
      user-select: none;
      @media screen and (max-width: 480px) {
        padding: 20px 10px;
      }
    }
  
    &__name {
      font-size: 18px;
      line-height: 1;
      white-space: nowrap;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      text-transform: uppercase;
      @media screen and (max-width: 480px) {
        font-size: 16px;
      }
    }
    &__nameItem {
      display: inline-block;
      min-width: 8px;
      position: relative;
    }
  
    &__number {
      font-weight: 500;
      line-height: 1;
      color: #fff;
      font-size: 27px;
      margin-bottom: 25px;
      display: inline-block;
      padding: 10px 15px;
      cursor: pointer;
  
      @media screen and (max-width: 480px) {
        font-size: 21px;
        margin-bottom: 15px;
        padding: 10px 10px;
      }
  
      @media screen and (max-width: 360px) {
        font-size: 19px;
        margin-bottom: 10px;
        padding: 10px 10px;
      }
    }
  
    &__numberItem {
      width: 16px;
      display: inline-block;
      font-size: 1.5rem;
      &.active {
        width: 30px;
      }
  
      @media screen and (max-width: 480px) {
        width: 13px;
  
        &.active {
          width: 16px;
        }
      }
  
      @media screen and (max-width: 360px) {
        width: 12px;
  
        &.active {
          width: 8px;
        }
      }
    }
  
    &__content {
      color: #fff;
      display: flex;
      align-items: flex-start;
    }
  
    &__date {
      flex-wrap: wrap;
      font-size: 16px;
      margin-left: auto;
      padding: 10px;
      display: inline-flex;
      width: 90px;
      white-space: nowrap;
      flex-shrink: 0;
      cursor: pointer;
  
      @media screen and (max-width: 480px) {
        font-size: 16px;
      }
    }
  
    &__dateItem {
      position: relative;
      span {
        width: 22px;
        display: inline-block;
      }
    }
  
    &__divider {
      padding: 0px 4px 0px 8px;
    }
  
    &__dateTitle {
      opacity: 0.7;
      font-size: 14px;
      padding-bottom: 6px;
      width: 100%;
  
      @media screen and (max-width: 480px) {
        font-size: 12px;
        padding-bottom: 5px;
      }
    }
    &__band {
      background: rgba(0, 0, 19, 0.8);
      width: 100%;
      height: 50px;
      margin-top: 30px;
      position: relative;
      z-index: 2;
      @media screen and (max-width: 480px) {
        margin-top: 20px;
      }
      @media screen and (max-width: 360px) {
        height: 40px;
        margin-top: 10px;
      }
    }
  
    &__cvv {
      text-align: right;
      position: relative;
      z-index: 2;
      padding: 15px;
      .card-item__type {
        opacity: 0.7;
      }
  
      @media screen and (max-width: 360px) {
        padding: 10px 15px;
      }
    }
    &__cvvTitle {
      padding-right: 10px;
      font-size: 15px;
      font-weight: 500;
      color: #fff;
      margin-bottom: 5px;
    }
    &__cvvBand {
      height: 45px;
      background: #fff;
      margin-bottom: 30px;
      text-align: right;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 10px;
      color: #1a3b5d;
      font-size: 18px;
      border-radius: 4px;
      box-shadow: 0px 10px 20px -7px rgba(32, 56, 117, 0.35);
  
      @media screen and (max-width: 480px) {
        height: 40px;
        margin-bottom: 20px;
      }
  
      @media screen and (max-width: 360px) {
        margin-bottom: 15px;
      }
    }
  }
  
  .card-list {
    margin-bottom: -130px;
  
    @media screen and (max-width: 480px) {
      margin-bottom: -120px;
    }
  }
  
  .card-input {
    margin-bottom: 20px;
    position: relative;
    &__label {
      font-size: 14px;
      margin-bottom: 5px;
      font-weight: 500;
      color: #1a3b5d;
      width: 100%;
      display: block;
      user-select: none;
    }
    &__input {
      width: 100%;
      height: 50px;
      border-radius: 5px;
      box-shadow: none;
      border: 1px solid #ced6e0;
      transition: all 0.3s ease-in-out;
      font-size: 18px;
      padding: 5px 15px;
      background: none;
      color: #1a3b5d;
      font-family: "Montserrat", sans-serif;
  
      &:hover,
      &:focus {
        border-color: #3d9cff;
      }
  
      &:focus {
        box-shadow: 0px 10px 20px -13px rgba(32, 56, 117, 0.35);
      }
      &.-select {
        -webkit-appearance: none;
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAeCAYAAABuUU38AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAUxJREFUeNrM1sEJwkAQBdCsngXPHsQO9O5FS7AAMVYgdqAd2IGCDWgFnryLFQiCZ8EGnJUNimiyM/tnk4HNEAg/8y6ZmMRVqz9eUJvRaSbvutCZ347bXVJy/ZnvTmdJ862Me+hAbZCTs6GHpyUi1tTSvPnqTpoWZPUa7W7ncT3vK4h4zVejy8QzM3WhVUO8ykI6jOxoGA4ig3BLHcNFSCGqGAkig2yqgpEiMsjSfY9LxYQg7L6r0X6wS29YJiYQYecemY+wHrXD1+bklGhpAhBDeu/JfIVGxaAQ9sb8CI+CQSJ+QmJg0Ii/EE2MBiIXooHRQhRCkBhNhBcEhLkwf05ZCG8ICCOpk0MULmvDSY2M8UawIRExLIQIEgHDRoghihgRIgiigBEjgiFATBACAgFgghEwSAAGgoBCBBgYAg5hYKAIFYgHBo6w9RRgAFfy160QuV8NAAAAAElFTkSuQmCC');
        background-size: 12px;
        background-position: 90% center;
        background-repeat: no-repeat;
        padding-right: 30px;
      }
    }
    &__eye {
      display: inline-flex;
      position: absolute;
      width: 1em;
      height: 1em;
      font-size: 24px;
      border-radius: 50%;
      top: 35px;
      right: 10px;
      opacity: .75;
      color: #8c9cae;
      cursor: pointer;
      padding: 0;
      background: none;
      display: inline-flex;
      border: 2px solid currentColor;
      box-shadow: none;
      transition: all .3s ease-in-out;
  
      &:before {
        content: '';
        position: absolute;
        background: white;
        width: 0.35em;
        height: 0.35em;
        top: 6px;
        left: 6px;
        z-index: 2;
        border-radius: 50%;
        transform: scale(.1);
        opacity: 0;
        transition: all .3s ease-in-out;
        transition-delay: .1s;
      }
  
      &:after {
        content: '';
        position: absolute;
        top: 3px;
        left: 3px;
        background: currentColor;
        width: 0.6em;
        height: 0.6em;
        border-radius: 50%;
        transform: scale(.1);
        opacity: 0;
        transition: all .3s ease-in-out;
      }
  
      &:hover:not(:disabled), &.active:not(:disabled) {
        color: #2364d2;
        opacity: 1;
      }
  
      &.active {
        &::before, &::after {
          transform: scale(1);
          opacity: 1;
        }
      }
  
      &:disabled {
        cursor: not-allowed;
        opacity: .4;
      }
    }
  }
  
  .slide-fade-up-enter.active {
    transition: all 0.25s ease-in-out;
    transition-delay: 0.1s;
    position: relative;
  }
  .slide-fade-up-leave.active {
    transition: all 0.25s ease-in-out;
    position: absolute;
  }
  .slide-fade-up-enter {
    opacity: 0;
    transform: translateY(15px);
    pointer-events: none;
  }
  .slide-fade-up-leave-to {
    opacity: 0;
    transform: translateY(-15px);
    pointer-events: none;
  }
  
  .slide-fade-right-enter.active {
    transition: all 0.25s ease-in-out;
    transition-delay: 0.1s;
    position: relative;
  }
  .slide-fade-right-leave.active {
    transition: all 0.25s ease-in-out;
    position: absolute;
  }
  .slide-fade-right-enter {
    opacity: 0;
    transform: translateX(10px) rotate(45deg);
    pointer-events: none;
  }
  .slide-fade-right-leave-to {
    opacity: 0;
    transform: translateX(-10px) rotate(45deg);
    pointer-events: none;
  }
  
  
  
  .github-btn {
    position: absolute;
    right: 40px;
    bottom: 50px;
    text-decoration: none;
    padding: 15px 25px;
    border-radius: 4px;
    box-shadow: 0px 4px 30px -6px rgba(36, 52, 70, 0.65);
    background: #24292e;
    color: #fff;
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 16px;
    text-align: center;
    transition: all .3s ease-in-out;
  
    @media screen and (min-width: 500px) {
      &:hover {
        transform: scale(1.1);
        box-shadow: 0px 17px 20px -6px rgba(36, 52, 70, 0.36);
      }
    }
  
    @media screen and (max-width: 700px) {
      position: relative;
      bottom: auto;
      right: auto;
      margin-top: 20px;
  
      &:active {
        transform: scale(1.1);
        box-shadow: 0px 17px 20px -6px rgba(36, 52, 70, 0.36);
      }
    }
  }
  
  .slide-fade-up {
    display: inline-block;
    & > div {transition: all 0.25s ease-in-out;}
  }
  .slide-fade-up-enter {
    opacity: 0;
    transform: translateY(-15px);
    pointer-events: none;
  }
  .slide-fade-up-enter-active {
    transition-delay: 0.1s;
    position: absolute;
  }
  .slide-fade-up-exit {
    opacity: 0;
    transform: translateY(15px);
    pointer-events: none;
  }
  .slide-fade-up-exit-active {
    position: relative;
  }
  