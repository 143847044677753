.TCLink :global {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.TCLink:after {
  box-sizing: border-box;
  display: block;
  top: 0;
  right: 0;
}